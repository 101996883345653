import { FC, ReactElement, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import {
  MultiList,
  ReactiveBase,
  ReactiveList,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticBaseUrl } from '@/utils/api'

interface Props {
  onChangeMatchingSales: (salesIds: string[]) => void
}

export const SaleTypeFilter: FC<Props> = ({
  onChangeMatchingSales,
}): ReactElement => {
  const { language } = useLanguageContext()

  const [currentMatch, setCurrentMatch] = useState<string[]>([])

  return (
    <ReactiveBase app="sales.sales" url={ElasticBaseUrl}>
      <FilterSection
        forceBottomSpacing
        label={<T>FrontDesk:DailyCustomerList.saleType.title</T>}
        render={({ isCollapsed }) => (
          <>
            <SelectorWrapper>
              <MultiList
                componentId="saleType"
                dataField="facet.name"
                filterLabel={translate(
                  'FrontDesk:DailyCustomerList.saleType.title',
                  language
                )}
                isCollapsed={isCollapsed}
                showCount={false}
                showCheckbox
                showSearch={false}
                URLParams
              />
            </SelectorWrapper>

            <ReactiveList
              componentId="saleType-results"
              dataField={'id'}
              includeFields={['id', 'sales.id']}
              // prevents search without a search value
              onData={({ data }: AnyObject) => {
                const onlyUnique = (value: string, index: number, self: any) =>
                  self.indexOf(value) === index

                const salesIds: string[] = []
                const dataArr = (data as AnyObject[]) || []

                dataArr.forEach((x) => {
                  if (typeof x?.id === 'string') {
                    salesIds.push(x.id)
                  }
                })

                const sortedUniqueIds = salesIds.filter(onlyUnique).sort()

                if (
                  JSON.stringify(sortedUniqueIds) !==
                  JSON.stringify(currentMatch)
                ) {
                  setCurrentMatch(sortedUniqueIds)
                  onChangeMatchingSales(sortedUniqueIds)
                }
              }}
              onQueryChange={function (prevQuery: any, nextQuery: any) {
                if ('match_all' in nextQuery['query']) {
                  nextQuery['query'] = { match_none: {} }
                }
              }}
              pagination={false}
              react={{
                and: ['saleType'],
              }}
              render={() => null}
              renderError={() => null}
              renderNoResults={() => null}
              showLoader={false}
              showResultStats={false}
              size={10000}
            />
          </>
        )}
      />
    </ReactiveBase>
  )
}

////////////

const SelectorWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`
